<template>
	<div class="bg-yellow flex flex-col items-start mt-8 w-11/12 self-center p-8">
		<div class="w-full flex items-center justify-between mb-4">
			<h2 class="my-auto text-2xl font-bold uppercase">Cargar compras</h2>
			<router-link
				class="p-2 rounded-lg bg-yellow hover:bg-yellow-500 font-bold uppercase border-2 border-black"
				style="color: white"
				to="/dashboard/listar-compras"
			>
				volver
			</router-link>
		</div>
		<form
			@submit.prevent="loadFile"
			class="flex flex-col gap-5 bg-white w-full py-7 px-10 border-2 border-black rounded-xl"
		>
			<div class="flex justify-evenly mb-4 w-full">
				<div
					class="text-right text-white px-7 py-3 rounded-lg bg-red-600"
					v-show="showDownloadFileWithError"
				>
					<a
						class="!text-white cursor-pointer text-left"
						@click="downloadFileWithError"
						>Descargar archivo con errores</a
					>
				</div>
				<div class="text-right text-white px-7 py-3 rounded-lg bg-green-600">
					<a
						href="/templates/TemplateCargarCompras.xlsx"
						download="TemplateCargarCompras.xlsx"
						class="!text-white cursor-pointer"
						>Descargar plantilla</a
					>
				</div>
			</div>
			<div class="mb-5 flex flex-col gap-y-4 items-center justify-center">
				<div class="px-7 py-2 border border-black rounded-lg font-bold">
					<select
						required
						v-model="bimester"
						class="outline-none"
						@change="period = ''"
					>
						<option value="" disabled selected>Seleccionar bimestre</option>
						<option :value="1">Bimestre 1</option>
						<option :value="2">Bimestre 2</option>
						<option :value="3">Bimestre 3</option>
						<option :value="4">Bimestre 4</option>
						<option :value="5">Bimestre 5</option>
						<option :value="6">Bimestre 6</option>
					</select>
				</div>
				<div class="px-7 py-2 border border-black rounded-lg font-bold">
					<select
						required
						v-model="period"
						:disabled="bimester == ''"
						class="outline-none"
					>
						<option value="" disabled selected>Seleccionar periodo</option>
						<option
							:value="period"
							v-for="period in periods[bimester]"
							:key="period"
						>
							{{ period }}
						</option>
					</select>
				</div>
			</div>
			<div class="w-full flex items-center justify-center">
				<input
					type="file"
					ref="archivos"
					class="cursor-pointer"
					accept=".xls, .xlsx"
					@change="changeFile"
				/>
			</div>
			<button
				type="submit"
				:disabled="!file || !period || !bimester"
				class="w-4/5 sm:w-2/5 lg:w-1/5 mt-4 p-3 mx-auto rounded-lg bg-yellow hover:bg-yellow-500 disabled:bg-gray-500 disabled:cursor-not-allowed text-white font-bold uppercase"
			>
				Subir archivo
			</button>
		</form>

		<Loader :isLoading="loadingData" />
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "@/components/Loader";

export default {
	components: { Loader },
	data: () => ({
		loadingData: false,
		file: null,
		showDownloadFileWithError: false,
		period: "",
		bimester: "",
		periods: {
			1: ["p01", "p02"],
			2: ["p03", "p04"],
			3: ["p05", "p06"],
			4: ["p07", "p08"],
			5: ["p09", "p10"],
			6: ["p11", "p12", "p13"],
		},
	}),
	methods: {
		...mapActions([
			"purchases/savePurchases",
			"purchases/loadFile",
			"purchases/downloadFileWithError",
		]),

		changeFile() {
			const file = this.$refs.archivos?.files[0];

			if (!file) {
				window.alert("Seleccione un archivo");
				this.file = null;
				this.$refs.archivos.value = "";
				return;
			}

			if (!file.name.includes(".xlsx") || !file.name.includes(".xls")) {
				window.alert("Solo se aceptan archivos de Excel");
				this.file = null;
				this.$refs.archivos.value = "";
				return;
			}

			this.file = file;
		},

		async loadFile() {
			if (!this.file) {
				window.alert("Seleccione un archivo");
				return;
			}

			this.loadingData = true;
			const file = await this.file.arrayBuffer();
			const res = await this["purchases/loadFile"](file);
			this.loadingData = false;

			if (res.error) {
				window.alert(res.message);
				this.showDownloadFileWithError = true;
				this.file = null;
				this.$refs.archivos.value = "";
				return;
			}
			this.showDownloadFileWithError = false;

			const confirmLoadData = confirm(
				"Archivo VALIDADO correctamente.\n¿Desea continuar con la operación?"
			);

			if (!confirmLoadData) return;

			this.loadingData = true;
			await this["purchases/savePurchases"]({
				period: this.period,
				bimester: this.bimester,
			});
			this.file = null;
			this.$refs.archivos.value = "";
			this.loadingData = false;

			window.alert("El archivo se a cargado con éxito.");
		},

		downloadFileWithError() {
			this.loadingData = true;
			this["purchases/downloadFileWithError"]();
			this.loadingData = false;
		},
	},
};
</script>
